
export const Math = {
    ALWAYS: 0,
    PARENS_DIVISION: 1,
    PARENS: 2
    // removed - STRICT_LEGACY: 3
};

export const RewriteUrls = {
    OFF: 0,
    LOCAL: 1,
    ALL: 2
};